import AppRoutes from "./routes";
import {ThemeProvider} from "styled-components";
import {useTheme} from "./hooks";
import GlobalStyles from "./theme/globalStyles";

function App() {
    const {colors} = useTheme();

    return (
        <ThemeProvider theme={colors}>
            <GlobalStyles/>
            <AppRoutes/>
        </ThemeProvider>
    );
}

export default App;
