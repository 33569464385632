import React from 'react'

export default function ICArrowBottom(props) {

    const {color} = props;
    return (
        <>
            <svg width="24" height="24" {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinejoin="round" strokeLinecap="round" strokeWidth={2} stroke={color}
                      d="M12 40V3"></path>
                <path strokeLinejoin="round" strokeLinecap="round" strokeWidth={2} stroke={color}
                      d="M6 15L12 24L18 15"></path>
            </svg>
        </>
    )
}
