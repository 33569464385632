import React from 'react';
import styled from "styled-components";
import {Accordion, AccordionItem as Item} from "@szhsin/react-accordion";
import styles from "../../assets/styles/accordion.module.css";
import chevronDown from "../../assets/svg/chevron-down.svg";
import chevronDownLight from "../../assets/svg/chevron-down-light.svg";
import {useTheme} from "../../hooks";

const AccordionItem = ({header, ...rest}) => {

    const {theme} = useTheme();
    return (
        <Item
            {...rest}
            header={
                <>
                    {header}
                    <img className={'chevron'} src={theme === 'dark' ? chevronDown : chevronDownLight}
                         alt="Chevron Down"/>
                </>
            }
            className={'item'}
            buttonProps={{
                className: ({isEnter}) =>
                    `${'faqItem'} ${isEnter && 'itemBtnExpanded'}`
            }}
            contentProps={{className: 'itemContent'}}
            panelProps={{className: 'itemPanel'}}
        />
    );
};

const FAQ = () => {
    return (
        <Wrapper>
            <div className="container">
                <div className="faq">
                    <div className="info">
                        <h2 className={'text-gradient'}>Frequently asked questions</h2>
                        <p>
                            You can find the answer to your question here if you do not understand how RubyPlay works
                        </p>
                    </div>
                    <div className={'accordion'}>
                        {/* `transitionTimeout` prop should be equal to the transition duration in CSS */}
                        <Accordion transition transitionTimeout={250}>
                            <AccordionItem header="TLDR; What is RubyPlay?" initialEntered>
                                <p>
                                    RubyPlay is a cross-platform native application for content distribution.
                                </p>
                            </AccordionItem>

                            <AccordionItem header="What are the use cases?">
                                <p>
                                    It is hard to create applications supporting every platform out there. That is why
                                    RubyPlay is designed for streaming providers so that they can distribute their
                                    content
                                    easily without reinventing the wheel, it's also possible to use it for your own.
                                </p>
                            </AccordionItem>

                            <AccordionItem header="Why is RubyPlay good?">
                                <p>
                                    RubyPlay is 100% native development. No web apps with poor performance and
                                    limitations.
                                    We can provide full functionality of platforms so that the user can have the best
                                    experience
                                    the app.
                                </p>
                            </AccordionItem>
                            <AccordionItem header="What if i need some unique features? ">
                                <p>
                                    RubyPlay is always up to date with the latest native features. But if you are an
                                    enterprise client and want something unique in your app, our team of experts will
                                    ship this feature for you in no time.
                                </p>
                            </AccordionItem>
                            <AccordionItem header="Is RubyPlay free?">
                                <p>
                                    RubyPlay is free to use for users and for clients for personal use. Enterprise
                                    clients (content providers) can limit their content to the end user as they desire.
                                </p>
                            </AccordionItem>
                            <AccordionItem header="Does RubyPlay provide any content?">
                                <p>
                                    No. RubyPlay does not provide any content, it is only a client side application.
                                </p>
                            </AccordionItem>

                            <AccordionItem header="What is a «Ruby»?">
                                <p>
                                    We refer to rubies as an applet
                                </p>
                            </AccordionItem>
                        </Accordion>
                    </div>
                </div>
            </div>
        </Wrapper>
    );
};

const Wrapper = styled.div`

  margin-top: calc(10rem * calc(1 - 0));
  margin-bottom: calc(10rem);
  min-height: 50vh;

  .faq {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  .itemContent {
    transition: height 0.3s ease-in-out;
  }

  .itemPanel {
    padding: 1rem;
  }

  .info {
    max-width: 400px;

    h2 {
      margin-bottom: 20px;
      text-align: center;
    }

    p {
      text-align: center;
    }
  }

  .accordion {
    width: 600px;
    border-radius: 5px;
      // background-color: ${props => props.theme.headerBackground} !important;
    // backdrop-filter: blur(12px);
    padding: 10px 0;
    padding-bottom: 0;
  }

  .faqItem {
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0;
    padding: 1rem;
    font-size: 1.2rem;
    text-align: left;
    color: ${props => props.theme.title};
    background-color: ${props => props.theme.headerBackground} !important;
    backdrop-filter: blur(12px);
    border: none;
    font-weight: 500;

  }

  .item {
    border-bottom: 1px solid ${props => props.theme.primary};


    p {
      color: ${props => props.theme.text};
    }
  }

  .itemBtn:hover {
    background-color: #f3f3f3 !important;
  }


  .itemContent {
    transition: height 0.25s cubic-bezier(0, 0, 0, 1);
    background-color: ${props => props.theme.headerBackground} !important;
    backdrop-filter: blur(12px);
    color: #fff;
  }

  .chevron {
    margin-left: auto;
    transition: transform 0.25s cubic-bezier(0, 0, 0, 1);
  }

  .itemBtnExpanded .chevron {
    transform: rotate(180deg);
  }


  @media (max-width: 1060px) {
    .faq {
      align-items: center;
      justify-content: center;

      .info {
        max-width: unset;
        margin-bottom: 2rem;
        text-align: center;
      }
    }
  }

`;

export default FAQ;
