import React from 'react';
import Layout from "../../layouts/Layout";
import styled from "styled-components";
import AppFooter from "../../components/common/AppFooter";
import snap1 from "../../assets/images/snap_1.png";
import snap2 from "../../assets/images/snap_2.png";
import SupportedPlatforms from "../../components/main/SupportedPlatforms";

const AboutUs = () => {

    return (
        <Layout>
            <Wrapper>
                <div className="container">

                    <h2>About Us</h2>
                    <div className="content">
                        <div>
                            <h3>What is RubyPlay</h3>
                            <p>RubyPlay is a native cross-platform application for creating and serving media pages.</p>
                        </div>
                        <div>
                            <h3>Use Cases</h3>
                            <p>Enjoy your own media content on any supported device with RubyPlay</p>
                            <p>Create your own local or public movie theater</p>
                            <p>Create your own secure home gallery</p>
                            <p>And anything that comes to your mind</p>
                        </div>
                        <div>
                            <h3>What i need to know as a user</h3>
                            <p>
                                As the user of the application, you just enter at the start screen the Ruby domain or
                                keyword, then you will be directed to the Ruby content
                            </p>
                        </div>
                        <div>
                            <h3>Example rubies</h3>
                            {/*<p>*/}
                            {/*    <b>example.blog</b> - Example blog Ruby*/}
                            {/*</p>*/}
                            {/*<p>*/}
                            {/*    <b>example.twitch</b> - Twitch player example Ruby*/}
                            {/*</p>*/}
                            <p>
                                <b>example.theater</b> - Charlie Chaplin's theater Ruby example
                            </p>
                        </div>

                        <div>
                            <h3>How it works</h3>
                            <p>As we mentioned RubyPlay is a native application. This means every platform requires it's
                                own implementation of RubyPlay. This is required to provide the best experience when
                                using the application, because a native application feels much better than a web
                                application.</p>

                            <p>
                                As a developer you can interact with RubyPlay by firstly submitting a request on the <a
                                className={'link__item'}
                                href="/integration">Integration</a> page. After we review your request, we will give you
                                access to RubyPlay's eco system including the development documentation and etc..
                            </p>
                            <p>
                                Define your Ruby metadata, theme color, icons and supported languages:
                            </p>
                            <div className={'flex'}>
                                <img className={'snap'} src={snap1} alt="Defining your application metadata info"/>
                            </div>
                            <p>After you receive access to the development eco system, you can now start building your
                                Ruby by creating REST API Endpoints. RubyPlay has predefined modes and API schemes that
                                the application will call when requesting data.</p>
                            <div className="flex">
                                <img className={'snap'} src={snap2} alt=""/>
                            </div>
                            <p>
                                The only thing that is required by you (the developer) is to adapt property names
                                and the API scheme by the documentation, the application will handle the other
                                stuff
                            </p>
                        </div>
                    </div>
                    {/*<SupportedPlatforms/>*/}
                </div>
                <AppFooter/>
            </Wrapper>
        </Layout>
    );
};

const Wrapper = styled.div`
  padding-top: 5rem;
  width: 100%;
  height: 100%;

  h2 {
    text-align: center;
    color: ${props => props.theme.title};
    margin: 3rem 0;
  }

  h3 {
    color: ${props => props.theme.title};
    font-size: 1.6rem;
    margin: 2rem 0 1rem 0;
  }

  p {
    margin: 0.8rem 0;
  }

  .snap {
    margin: 0 auto;
    width: 100%;
    max-width: 700px;
    height: auto;
    object-fit: contain;
  }

  .flex {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2rem 0;
  }
`;

export default AboutUs;
