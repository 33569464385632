import React from 'react';
import styled from "styled-components";
import featuresWave from "../../assets/svg/features-wave.svg";
import FeatureCard from "../cards/FeatureCard";

const Features = () => {
    return (
        <Wrapper>
            <div className="container">
                <h2 className={'text-gradient'}>Features</h2>
                <div className="items">
                    <FeatureCard title={'Push notifications API'}
                                 text={'Send notifications to your users to keep them up to date'}/>
                    <FeatureCard title={'Adaptive streaming'}
                                 text={'Our media player supports adaptive streaming'}/>
                    <FeatureCard title={'H264, HEVC & much more'}
                                 text={'We support almost any media codec that you can think of'}/>
                    <FeatureCard title={'AirPlay & Chromecast'}
                                 text={'Your users can go big screen easily'}/>
                    <FeatureCard title={'Authentication support'}
                                 text={'Authenticate users with basic auth or 2FA'}/>
                    <FeatureCard title={'Collect payments'}
                                 text={'You can easily start collecting payments from your users using our API'}/>
                    <FeatureCard title={'Content aggregation'}
                                 text={'Aggregate users movies, series using genres, folders and etc...'}/>
                    <FeatureCard title={'Profiles'}
                                 text={'Users can create profiles for there family members'}/>
                    <FeatureCard title={'WebSockets support'}
                                 text={'Make your app real-time!'}/>
                    <FeatureCard title={'Optimized lists'}
                                 text={'All the lists in our apps are optimized for large contents'}/>
                    <FeatureCard title={'Search & extended search'}
                                 text={'Allow your users to search across your database'}/>
                    <FeatureCard title={'Dynamic rows'}
                                 text={'You can build recommendations, show collections of content than you want'}/>
                    <FeatureCard title={'Offline watch'}
                                 text={'Your users can save content to their devices to later watch with no internet'}/>
                </div>
                <p className={'etc'}>And much more...</p>
            </div>
        </Wrapper>
    );
};

const Wrapper = styled.div`
  position: relative;
  margin-top: calc(10rem * calc(1 - 0));
  margin-bottom: calc(10rem);

  img {
    width: 100%;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
  }

  h2 {
    text-align: center;
  }

  .items {
    margin-top: 4rem;
    display: flex;
    justify-content: space-around;
    gap: 10px;
    flex-wrap: wrap;
  }

  .etc {
    text-align: center;
    margin: 2rem 0;
  }

  .items {
    div:nth-child(even) {
      background: linear-gradient(45deg,
      var(--base) 25%,
      var(--complimentary2));
      border: none;

      p,h3 {
        color: #fff !important;
      }
    }
  }

`;

export default Features;
