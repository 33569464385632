import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import Button from "../buttons/Button";

const Integrating = () => {

    const [values, setValues] = useState({
        rubyName: null,
        rubyType: null,
        name: null,
        email: null,
        description: null,
    });

    const [message, setMessage] = useState(null);
    const [toastVisible, setToastVisible] = useState(false);
    const [success, setSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const timeout = useRef(null);


    useEffect(() => {

        if (toastVisible) {
            timeout.current = setTimeout(() => {
                setToastVisible(false);
            }, 5000);
        }

        return () => {
            if (timeout.current) {
                window.clearTimeout(timeout.current);
                timeout.current = null;
            }
        }
    }, [toastVisible]);

    const onValueChange = (key, value) => {

        setValues({
            ...values,
            [key]: value
        })
    }

    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    const onSubmit = async () => {
        setSuccess(false);
        if (!values.description) {
            setMessage('Description should not be empty');
            setToastVisible(true);
            return;
        }

        if (!values.name) {
            setMessage('Your name should not be empty');
            setToastVisible(true);
            return;
        }

        if (!values.email) {
            setMessage('E-mail should not be empty');
            setToastVisible(true);
            return;
        }

        if (!values.rubyName) {
            setMessage('Ruby name should not be empty');
            setToastVisible(true);
            return;
        }

        if (!values.rubyType) {
            setMessage('Ruby type should not be empty');
            setToastVisible(true);
            return;
        }

        if (!validateEmail(values.email)) {
            setMessage('You entered an invalid E-mail');
            setToastVisible(true);
            return;
        }


        setMessage('Your message was successfully sent. We will contact you as soon as possible.');
        setToastVisible(true);
        setSuccess(true);


        setIsLoading(true);
        try {
            await fetch('https://api.rubyplay.app/integration', {
                method: 'POST',
                body: JSON.stringify(values),
                // mode: 'no-cors',
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(res => res.json()).then(res => res);
        } catch (e) {

        }
        setIsLoading(false);


        setValues({
            rubyName: '',
            rubyType: '',
            name: '',
            email: '',
            description: '',
        });
    }

    return (
        <Wrapper>
            <div className={`toast ${toastVisible ? 'visible' : ''} ${success ? 'success' : ''}`}>
                {message}
            </div>
            <div className="container">
                <div className="flex">
                    <div className="item">
                        <h2 className={'text-gradient'}>Submit your Ruby</h2>
                        <p>In order to receive access to the documentation and development mode on RubyPlay, you have to
                            submit
                            this form for review</p>
                    </div>

                    <div className="form">
                        <div className="fm__group">
                            <div className="fm__item">
                                <p>Ruby name</p>
                                <input value={values.rubyName} onChange={e => onValueChange('rubyName', e.target.value)}
                                       type="text" placeholder={'Ruby Name'}/>
                            </div>
                            <div className="fm__item">
                                <p>Ruby type</p>
                                <input value={values.rubyType} onChange={e => onValueChange('rubyType', e.target.value)}
                                       type="text" placeholder={'Theater, gallery etc..'}/>
                            </div>
                        </div>
                        <div className="fm__group">
                            <div className="fm__item">
                                <p>Your name</p>
                                <input value={values.name} onChange={e => onValueChange('name', e.target.value)}
                                       type="text" placeholder={'Name here'}/>
                            </div>
                            <div className="fm__item">
                                <p>Contact E-Mail</p>
                                <input value={values.email} onChange={e => onValueChange('email', e.target.value)}
                                       type="email" placeholder={'E-mail here'}/>
                            </div>
                        </div>
                        <div className="fm__group fm__full">
                            <div className="fm__item">
                                <p>Ruby description</p>
                                <textarea value={values.description}
                                          onChange={e => onValueChange('description', e.target.value)}
                                          placeholder={'Describe your Ruby'}/>
                            </div>
                        </div>
                        <div className="fm__group fm__full"
                             style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <Button disabled={isLoading} onClick={onSubmit}>
                                {isLoading ? 'Loading...' : 'Submit Ruby'}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    );
};

const Wrapper = styled.div`
  width: 100%;

  h2 {
    text-align: center;
    margin: 3rem 0;

  }

  p {
    text-align: center;
  }

  .flex {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  .item {
    max-width: 400px;
  }

  .form {
    display: flex;
    justify-content: space-between;
    max-width: 600px;
    flex-wrap: wrap;
    gap: 15px;

    .fm__group {
    }

    .fm__full {
      flex: 0 0 100%;
    }

    .fm__item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      margin: 1rem 0;
    }

    p {
      color: ${props => props.theme.title};
      margin: 0.5rem 0;
    }

    input {
      outline: none;
      border: 2px solid ${props => props.theme.divider};
      height: 40px;
      min-width: 270px;
      padding: 0 10px;
      background-color: ${props => props.theme.headerBackground} !important;
      backdrop-filter: blur(12px);
      color: ${props => props.theme.title};
      border-radius: 5px;

      &:focus {
        border: 2px solid ${props => props.theme.primary};
          //outline: 2px solid ${props => props.theme.primary};
      }

      font-size: 1rem;
    }

    textarea {
      outline: none;
      flex: 1;
      width: 100%;
      padding: 10px;
      min-height: 120px;
      resize: none;
      background-color: ${props => props.theme.headerBackground} !important;
      border: 2px solid ${props => props.theme.divider};
      backdrop-filter: blur(12px);
      color: ${props => props.theme.title};
      border-radius: 5px;

      &:focus {
        border: 2px solid ${props => props.theme.primary};
          //outline: 2px solid ${props => props.theme.primary};
      }

      font-size: 1rem;

    }
  }

  @media (max-width: 1060px) {
    .flex {
      justify-content: center;
    }
  }

  @media (max-width: 620px) {
    .form {
      justify-content: center;
    }

    .fm__group {
      flex: 0 0 100%;
    }

    input {
      width: 100%;
    }
  }


  .toast {
    width: 300px;
    position: absolute;
    top: 100px;
    right: 9px;
    z-index: 9999999;
    background: #ff0e0e;
    color: ${props => props.theme.title};
    padding: 20px;
    border-radius: 5px;

    transition: 200ms transform;
    transform: translateX(1000px);

    &.success {
      background: linear-gradient(45deg,
      var(--base) 25%,
      var(--complimentary2));
    }

    &.visible {
      transition: 200ms transform;
      transform: translateX(0px);

    }
  }
`;

export default Integrating;
