import React from 'react';
import styled from "styled-components";

const PlatformCard = ({platformName, icon, openContentText, isComingSoon, noText = false, href = undefined}) => {
    return (
        <Wrapper isComingSoon={isComingSoon} href={href} target={'_blank'}>
            <h3>{platformName}</h3>
            {icon}
            {(isComingSoon && !noText)
                ? <div>
                    <p>Coming soon...</p>
                </div>
                : <div>
                    <p>{openContentText}</p>
                </div>}
        </Wrapper>
    );
};

const Wrapper = styled.a`
  background-color: ${props => props.theme.headerBackground} !important;
  backdrop-filter: blur(12px);
  padding: 1rem;
  position: relative;
  border-radius: 5px;
  width: 280px;
  cursor: ${props => props.isComingSoon ? 'not-allowed' : 'pointer'};
  border: 2px solid transparent;

  opacity: ${props => props.isComingSoon ? 0.4 : 1};

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  svg {
    //width: 100px;
    //height: 100px;
    margin-top: 15px;
  }

  h3 {
    color: ${props => props.theme.title} !important;
    font-size: 2rem;
  }

  transition: 200ms;

  &:hover {
    transition: 200ms;
    border-color: ${props => props.theme.primary};
  }

  p {
    margin-top: 20px;
    text-align: center;
  }
`;

export default PlatformCard;
