import React from 'react';
import Layout from "../../layouts/Layout";
import styled from "styled-components";
import Integrating from "../../components/main/Integrating";
import AppFooter from "../../components/common/AppFooter";
import FAQ from "../../components/main/FAQ";

const IntegrationPage = () => {
    return (
        <Layout>
            <Wrapper>
                <h2>Ruby Integration</h2>
                <Integrating/>
                <FAQ/>
                <AppFooter/>
            </Wrapper>
        </Layout>
    );
};

const Wrapper = styled.div`
  width: 100%;

  padding-top: 5rem;
  width: 100%;
  height: 100%;

  h2 {
    text-align: center;
    color: ${props => props.theme.title};
    margin: 3rem 0;
  }
`;

export default IntegrationPage;
