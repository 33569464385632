import React from 'react'

export default function (props) {
    return (
        <>
            <svg width="32" height="32" {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinejoin="round" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1"
                      stroke="#ff0e0e" fill="none" d="M17 3H7L2 12L7 21H17L22 12L17 3Z"></path>
                <path strokeLinejoin="round" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1"
                      stroke="#ff0e0e" d="M12 7V13"></path>
                <path strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1" stroke="#ff0e0e"
                      d="M11.99 17H12.01"></path>
            </svg>
        </>
    )
}
