import React, {memo, useEffect, useRef} from 'react';
import * as PIXI from "pixi.js";
import {KawaseBlurFilter} from "@pixi/filter-kawase-blur";
import SimplexNoise from "simplex-noise";
import hsl from "hsl-to-hex";
import debounce from "debounce";
import styled from "styled-components";
import {useEventListener} from "usehooks-ts";
import Button from "../buttons/Button";
import {useNavigate} from "react-router-dom";

function random(min, max) {
    return Math.random() * (max - min) + min;
}

function map(n, start1, end1, start2, end2) {
    return ((n - start1) / (end1 - start1)) * (end2 - start2) + start2;
}

const simplex = new SimplexNoise();

class ColorPalette {
    constructor() {
        this.setColors();
        this.setCustomProperties();
    }

    setColors() {
        // pick a random hue somewhere between 220 and 360
        this.hue = ~~random(262, 262);

        this.complimentaryHue1 = this.hue + 30;
        this.complimentaryHue2 = this.hue + 60;
        // define a fixed saturation and lightness
        this.saturation = 95;
        this.lightness = 50;

        // define a base color
        this.baseColor = hsl(this.hue, this.saturation, this.lightness);
        // define a complimentary color, 30 degress away from the base
        this.complimentaryColor1 = hsl(
            this.complimentaryHue1,
            this.saturation,
            this.lightness
        );
        // define a second complimentary color, 60 degrees away from the base
        this.complimentaryColor2 = hsl(
            this.complimentaryHue2,
            this.saturation,
            this.lightness
        );

        // store the color choices in an array so that a random one can be picked later
        this.colorChoices = [
            this.baseColor,
            this.complimentaryColor1,
            this.complimentaryColor2
        ];
    }

    randomColor() {
        // pick a random color
        return this.colorChoices[~~random(0, this.colorChoices.length)].replace(
            "#",
            "0x"
        );
    }

    setCustomProperties() {
        // set CSS custom properties so that the colors defined here can be used throughout the UI
        // document.documentElement.style.setProperty("--hue", this.hue);
        // document.documentElement.style.setProperty(
        //     "--hue-complimentary1",
        //     this.complimentaryHue1
        // );
        // document.documentElement.style.setProperty(
        //     "--hue-complimentary2",
        //     this.complimentaryHue2
        // );
    }
}

const Presentation = () => {


    const boundsRef = useRef(null);
    const pixiApp = useRef(null);
    const navigation = useNavigate();

// Orb class
    class Orb {
        // Pixi takes hex colors as hexidecimal literals (0x rather than a string with '#')
        constructor(fill = 0x000000) {
            // bounds = the area an orb is "allowed" to move within
            boundsRef.current = setBounds();
            // initialise the orb's { x, y } values to a random point within it's bounds
            this.x = random(boundsRef.current["x"].min, boundsRef.current["x"].max);
            this.y = random(boundsRef.current["y"].min, boundsRef.current["y"].max);

            // how large the orb is vs it's original radius (this will modulate over time)
            this.scale = 1;

            // what color is the orb?
            this.fill = fill;

            // the original radius of the orb, set relative to window height
            this.radius = random(window.innerHeight / 6, window.innerHeight / 3);

            // starting points in "time" for the noise/self similar random values
            this.xOff = random(0, 1000);
            this.yOff = random(0, 1000);
            // how quickly the noise/self similar random values step through time
            this.inc = 0.002;

            // PIXI.Graphics is used to draw 2d primitives (in this case a circle) to the canvas
            this.graphics = new PIXI.Graphics();
            this.graphics.alpha = 0.825;
        }

        update() {
            // self similar "psuedo-random" or noise values at a given point in "time"
            const xNoise = simplex.noise2D(this.xOff, this.xOff);
            const yNoise = simplex.noise2D(this.yOff, this.yOff);
            const scaleNoise = simplex.noise2D(this.xOff, this.yOff);

            // map the xNoise/yNoise values (between -1 and 1) to a point within the orb's bounds
            this.x = map(xNoise, -1, 1, boundsRef.current["x"].min, boundsRef.current["x"].max);
            this.y = map(yNoise, -1, 1, boundsRef.current["y"].min, boundsRef.current["y"].max);
            // map scaleNoise (between -1 and 1) to a scale value somewhere between half of the orb's original size, and 100% of it's original size
            this.scale = map(scaleNoise, -1, 1, 0.5, 1);

            // step through "time"
            this.xOff += this.inc;
            this.yOff += this.inc;
        }

        render() {
            // update the PIXI.Graphics position and scale values
            this.graphics.x = this.x;
            this.graphics.y = this.y;
            this.graphics.scale.set(this.scale);

            // clear anything currently drawn to graphics
            this.graphics.clear();

            // tell graphics to fill any shapes drawn after this with the orb's fill color
            this.graphics.beginFill(this.fill);
            // draw a circle at { 0, 0 } with it's size set by this.radius
            this.graphics.drawCircle(0, 0, this.radius);
            // let graphics know we won't be filling in any more shapes
            this.graphics.endFill();
        }
    }

    useEventListener('resize', debounce(() => {
        boundsRef.current = setBounds();
    }, 250), window);

    useEffect(() => {

// Create PixiJS app
        pixiApp.current = new PIXI.Application({
            // render to <canvas class="orb-canvas"></canvas>
            view: document.querySelector(".orb-canvas"),
            // auto adjust size to fit the current window
            resizeTo: window,
            // transparent background, we will be creating a gradient background later using CSS
            transparent: true
        });

        pixiApp.current.stage.filters = [new KawaseBlurFilter(30, 10, true)];


// Create colour palette
        const colorPalette = new ColorPalette();

// Create orbs
        const orbs = [];

        for (let i = 0; i < 10; i++) {
            const orb = new Orb(colorPalette.randomColor());

            pixiApp.current.stage.addChild(orb.graphics);

            orbs.push(orb);
        }

// Animate!
        if (!window.matchMedia("(prefers-reduced-motion: reduce)").matches) {
            pixiApp.current.ticker.add(() => {
                orbs.forEach((orb) => {
                    orb.update();
                    orb.render();
                });
            });
        } else {
            orbs.forEach((orb) => {
                orb.update();
                orb.render();
            });
        }

        return () => {
            pixiApp.current?.destroy();
        }
    }, []);

    const setBounds = () => {
        // how far from the { x, y } origin can each orb move
        const maxDist =
            window.innerWidth < 1000 ? window.innerWidth / 3 : window.innerWidth / 5;
        // the { x, y } origin for each orb (the bottom right of the screen)
        const originX = window.innerWidth / 1.25;
        const originY =
            window.innerWidth < 1000
                ? window.innerHeight
                : window.innerHeight / 1.375;

        // allow each orb to move x distance away from it's x / y origin
        return {
            x: {
                min: originX - maxDist,
                max: originX + maxDist
            },
            y: {
                min: originY - maxDist,
                max: originY + maxDist
            }
        };
    }

    const whatIsRubyPlay = () => {
        navigation('/about-us')
    }

    const getStarted = () => {
        navigation('/integration')
    }

    return (
        <Wrapper>
            <div className="container">
                <div className="welcome">
                    <h1>Streaming revolution in</h1>
                    <div className="heading">
                        <h1 className={'text-gradient'}>Media Standards</h1>
                        <div className="gradient__bg">
                        </div>
                    </div>
                    <p>Revolutionizing media entertainment, helping you to deliver your content to users with no
                        efforts. <br/>
                        Our
                        skilled engineers design immersive, quality
                        experiences catering to diverse client fantasies.
                    </p>

                    <div className="actions">
                        <Button onClick={whatIsRubyPlay} type={'outline'}>
                            What is RubyPlay?
                        </Button>
                        <Button onClick={getStarted}>
                            Get started
                        </Button>
                    </div>
                </div>
            </div>
        </Wrapper>
    );
};

const Wrapper = styled.div`
  position: relative;
  //min-height: 100vh;
  width: 100%;

  .welcome {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 8rem;
  }

  .actions {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 25px;
    justify-content: center;
    margin-top: 1.5rem;
  }

  p {
    margin: 1rem 0;
    text-align: center;
  }


  .heading {
    position: relative;
    width: 100%;
  }

  .gradient__bg {
    position: absolute;
    bottom: 0;
    top: 0;
    right: 0;
    left: 0;
    margin: 0 auto;
    height: 150px;
    width: 90%;
    border-radius: 50%;
    filter: blur(64px);
    background-color: rgb(123 83 191/0.5);
  }

  h1 {
    text-align: center;
  }

  @media (max-width: 710px) {
    h1 {
      font-size: 3rem;
    }
  }
`;

export default memo(Presentation, () => true);
