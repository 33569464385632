export const LIGHT_THEME = {
    backgroundGradient: `linear-gradient(
            to bottom,
            hsl(262, 95%, 99%),
            hsl(262, 95%, 84%)
    )`,
    primary: '#7b53bf',
    title: '#000',
    text: '#232023',
    card: '#fff',
    buttonSecondary: '#000',
    buttonSecondaryText: '#fff',
    headerBackground: 'hsla(0, 0%, 95%, .8)',
    divider: '#d4cfcf',
}


export const DARK_THEME = {
    backgroundGradient: `linear-gradient(
            to bottom,
            hsl(262, 0%, 0%),
            hsl(262, 0%, 0%)
    )`,
    primary: '#7b53bf',
    title: '#fff',
    text: '#a7a5a7',
    card: '#000',
    buttonSecondary: '#fff',
    buttonSecondaryText: '#000',
    headerBackground: 'hsla(0, 0%, 1%, .8)',
    divider: 'rgb(67, 67, 67)'
}
