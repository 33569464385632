import React, {useMemo} from 'react';
import {useAppearanceStore} from "../store";
import {shallow} from "zustand/shallow";
import {DARK_THEME, LIGHT_THEME} from "../theme";

const useThemeHook = () => {

    const {theme, setTheme} = useAppearanceStore(state => ({
        theme: state.theme,
        setTheme: state.setTheme
    }), shallow);

    const colors = useMemo(() => {
        return theme === 'dark' ? DARK_THEME : LIGHT_THEME;
    }, [theme]);

    return {theme, setTheme, colors}
};

export default useThemeHook;
