import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {useSearchParams} from "react-router-dom";
import Button from "../../../components/buttons/Button";
import {ArrowBottomIcon} from "../../../icons";
import {useTheme} from "../../../hooks";
import {useEventListener} from "usehooks-ts";

const RubyPlayTwitchPlayer = () => {

    const {colors, setTheme} = useTheme();
    const [query] = useSearchParams();

    const THEME = query.get('theme');

    const [channelName, setChannelName] = useState(null);
    const [controlsVisible, setControlsVisible] = useState(true);
    const [hideVisible, setHideVisible] = useState(false);

    const channelNameRef = useRef(null);
    const timeoutRef = useRef(null);
    const iframeRef = useRef(null);


    useEffect(() => {
        setTheme(THEME === 'dark' ? 'dark' : 'light');
    }, [THEME]);
    useEffect(() => {

        return () => {
            if (timeoutRef.current) {
                window.clearTimeout(timeoutRef.current);
                timeoutRef.current = null;
            }
        }
    }, [query]);


    const onAnyActivity = () => {
        return;
        console.log(123);
        setHideVisible(true);

        if (timeoutRef.current) {
            window.clearTimeout(timeoutRef.current);
            timeoutRef.current = null;
        }

        timeoutRef.current = setTimeout(() => {
            setHideVisible(false);
        }, 5000);
    }

    const onIframeFocus = (e) => {
        if (document.activeElement.tagName === "IFRAME") {
            console.log("clicked");
        }
    }


    useEventListener('keydown', onAnyActivity, document);
    useEventListener('mousemove', onAnyActivity, document);
    useEventListener('mousedown', onAnyActivity, document);
    useEventListener('mousedown', onAnyActivity, document);
    useEventListener('click', onAnyActivity, document.body);
    useEventListener('blur', onIframeFocus, window);


    const showControls = () => {
        setControlsVisible(true);
    }
    const onHide = () => {
        setControlsVisible(false);
    }
    const onChannelNameChange = (e) => {
        channelNameRef.current = e.target.value;
    }

    const onChangeChannel = () => {
        setChannelName(channelNameRef.current);
        onHide();
    }

    return (
        <Wrapper>
            {channelName && <iframe
                onMouseMove={onAnyActivity}
                ref={iframeRef}
                key={channelName}
                src={`https://player.twitch.tv/?channel=${channelName}&parent=rubyplay.app`}
                className={'frame'}
                allowFullScreen>
            </iframe>}

            {!channelName && <div className="welcome">
                <h2>RubyPlay Twitch Player</h2>
                <p>Enter twitch channel name to begin the stream!</p>
            </div>}
            {<div className="open__controls" onClick={showControls}>
                <ArrowBottomIcon color={colors.title}/>
            </div>}
            <div className={`controls ${controlsVisible ? 'visible' : ''}`}>
                <div className="">
                    <input onChange={onChannelNameChange} placeholder={'Channel name here'} type="text"/>
                </div>
                <div className="close">
                    <Button onClick={onChangeChannel}>Confirm</Button>
                </div>
            </div>
        </Wrapper>
    );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;


  .welcome {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 1;

    h2 {
      color: ${props => props.theme.title};
    }

    p {
      margin-top: 10px;
    }
  }

  .frame {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    border: none;
  }

  .controls {
    background-color: ${props => props.theme.headerBackground};
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    right: 0;
    z-index: 3;

    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 10px 20px;
    padding-bottom: 40px;

    input {
      margin-right: 10px;
      outline: none;
      border: 2px solid ${props => props.theme.divider};
      height: 40px;
      min-width: 100px;
      padding: 0 10px;
      background-color: ${props => props.theme.headerBackground} !important;
      backdrop-filter: blur(12px);
      color: ${props => props.theme.title};
      border-radius: 5px;

      &:focus {
        border: 2px solid ${props => props.theme.primary};
          //outline: 2px solid ${props => props.theme.primary};
      }

      font-size: 1rem;
      
    }

    opacity: 0;
    transform: translateY(200px);
    transition: 200ms;

    &.visible {
      transform: translateY(0px);
      transition: 200ms;
      opacity: 1;
    }

    .close {
      cursor: pointer;
    }
  }

  .open__controls {
    cursor: pointer;
    position: absolute;
    bottom: 0;
    width: 100%;
    transform: rotate(180deg);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    z-index: 2;
    padding-top: 40px;
  }
`;

export default RubyPlayTwitchPlayer;
