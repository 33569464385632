import {createJSONStorage, persist} from "zustand/middleware";
import {create} from "zustand";

const useAppearanceStore = persist((set, get) => ({
    theme: 'dark',
    setTheme(theme) {
        set({
            theme
        })
    }
}), {
    name: 'appearance-storage',
    storage: createJSONStorage(() => localStorage),
});

export default create(useAppearanceStore);
