import {createGlobalStyle} from "styled-components";

const GlobalStyles = createGlobalStyle`
  body {
    background: ${props => props.theme.backgroundGradient};
  }

  h1 {
    font-size: 7rem;
    color: ${props => props.theme.title};
  }

  h2 {
    font-size: 3rem;
  }

  p {
    color: ${props => props.theme.text};
    line-height: 1.7rem;
    font-size: 1.2rem;
    font-weight: 400;
  }

  .link__item {
    cursor: pointer;
    display: inline-block;
    color: ${props => props.theme.text};
    text-decoration: none;

    font-weight: 500;

    &::after {
      position: relative;
      top: 4px;
      content: "";
      display: block;
      width: 0;
      height: 2px;
      background: ${props => props.theme.title};
      transition: width 0.3s;
    }

    &:hover::after,
    &:focus::after {
      width: 100%;
      transition: width 0.3s;
    }

    &:hover {
      transition: color 0.2s;
      color: ${props => props.theme.title};
    }

    &.active {
      color: ${props => props.theme.title};
    }
  }

  li {
    list-style: none;
    display: inline-block;
    margin-right: 20px;
  }
`;

export default GlobalStyles;
