import React from 'react';
import styled from "styled-components";
import Layout from "../../layouts/Layout";
import Presentation from "../../components/main/Presentation";
import Features from "../../components/main/Features";
import HowItWorks from "../../components/main/HowItWorks";
import FAQ from "../../components/main/FAQ";
import SupportedPlatforms from "../../components/main/SupportedPlatforms";
import Integrating from "../../components/main/Integrating";
import AppFooter from "../../components/common/AppFooter";

const Home = () => {
    return (
        <Layout>
            <Wrapper>
                <canvas className="orb-canvas"></canvas>
                <Presentation/>
                <HowItWorks/>
                <Features/>
                <FAQ/>
                <SupportedPlatforms/>
                <Integrating/>
                <AppFooter/>
            </Wrapper>
        </Layout>
    );
};

const Wrapper = styled.div`
  width: 100%;
  overflow: auto;
  height: 100vh;
`;

export default Home;
