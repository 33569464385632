import React from 'react';
import styled from "styled-components";
import AppHeader from "../components/common/AppHeader";

const Layout = ({children}) => {
    return (
        <Wrapper>
            <AppHeader/>
            {children}
        </Wrapper>
    );
};

const Wrapper = styled.div`
  position: relative;
  z-index: 3;
  width: 100%;
  height: 100%;
`;

export default Layout;
