import React, {useState} from 'react';
import styled from "styled-components";
import AppLogo from "../../assets/svg/logo.svg";
import Switch from "react-switch";
import {useTheme} from "../../hooks";
import {DarkModeIcon, LightModeIcon} from "../../icons";
import {useLocation, useNavigate} from "react-router-dom";

const AppHeader = () => {

    let location = useLocation();
    const navigation = useNavigate();

    const {theme, setTheme, colors} = useTheme();

    const onThemeChange = (e) => {
        setTheme(!e ? 'light' : 'dark');
    }

    const navigate = (e, path) => {
        e.preventDefault();
        e.stopPropagation();

        if (window.location.host === 'pay.rubyplay.app') {
            window.location.href = `https://rubyplay.app${path}`;
            return;
        }

        navigation(path)
    }

    return (
        <Wrapper>
            <div className="container">
                <div className="logo__wrapper" onClick={() => navigation('/')}>
                    <img className={'logo'} src={AppLogo}/>
                    <h1 className={'text-gradient'}>RubyPlay</h1>
                </div>
                <ul className="items">
                    <li>
                        <div onClick={e => navigate(e, '/integration')}
                             className={`link__item ${location.pathname === '/integration' ? 'active' : ''}`}>
                            Integration
                        </div>
                    </li>
                    <li>
                        <div onClick={e => navigate(e, '/about-us')}
                             className={`link__item ${location.pathname === '/about-us' ? 'active' : ''}`}>
                            About us
                        </div>
                    </li>
                    <li>
                        <div onClick={e => navigate(e, '/pp')}
                             className={`link__item ${location.pathname === '/pp' ? 'active' : ''}`}>Privacy
                            Policy
                        </div>
                    </li>
                    <li>
                        <div onClick={e => navigate(e, '/tos')}
                             className={`link__item ${location.pathname === '/tos' ? 'active' : ''}`}>Terms and
                            Conditions
                        </div>
                    </li>
                </ul>
                <div className="actions">
                    <Switch onChange={onThemeChange} checked={theme === 'dark'} onColor={colors.primary}
                            checkedIcon={<div className={'icon__wrap'}>
                                <DarkModeIcon color={'#fff'} width={20}
                                              height={20}/>
                            </div>}
                            uncheckedIcon={<div className={'icon__wrap'}>
                                <LightModeIcon color={'#fff'} width={20}
                                               height={20}/>
                            </div>}/>
                </div>
            </div>
        </Wrapper>
    );
};


const Wrapper = styled.div`
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: ${props => props.theme.headerBackground} !important;
  border-bottom: 1px solid ${props => props.theme.divider};

  backdrop-filter: blur(12px);

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;

  }

  .logo__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    cursor: pointer;
  }

  .logo {
    width: 60px;
    height: 60px;
  }

  h1 {
    font-size: 2rem;
  }

  .items {
    //flex: 1;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .actions {
    width: 200px;
  }

  .icon__wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  @media (max-width: 1050px) {
    .actions {
      width: 70px;
    }
  }

  @media (max-width: 738px) {
    .items {
      display: none;
    }
  }
`;

export default AppHeader;
