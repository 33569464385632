import React from 'react'

export default function ICApple(props) {
    const {color} = props;

    return (
        <>
            <svg width="24" height="24" {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinejoin="round" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1"
                      stroke={color} fill={color}
                      d="M19.99 10.3167C19.2804 9.11363 17.2017 8 15.9625 8C14.3235 8 13.634 8.99431 12.5047 8.99431C11.3354 8.99431 10.2661 8.05966 8.85696 8.05966C6.92817 8.0696 4 9.83948 4 14.0056C4 17.794 7.45784 21.9999 9.41661 21.9999C10.6059 22.0098 10.8957 21.2542 12.5047 21.2542C14.1137 21.2442 14.4635 22.0098 15.6527 21.9999C16.9919 21.99 18.0312 20.5283 18.8007 19.3749C19.3504 18.5496 19.5703 18.1221 20 17.1874C16.842 16.4019 16.2124 11.3608 19.99 10.3167Z"></path>
                <path strokeLinejoin="round" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1"
                      stroke={color} fill={color}
                      d="M15.2671 4.93C15.9666 4.17 16.4863 3.1 16.2964 2C15.1572 2.07 13.858 2.7 13.0785 3.5C12.3789 4.23 11.8292 5.32 12.0491 6.37C13.2883 6.4 14.5275 5.75 15.2671 4.93Z"></path>
            </svg>
        </>
    )
}
