import React from 'react';
import styled from "styled-components";
import PlatformCard from "../cards/PlatformCard";
import {AndroidIcon, AndroidTvIcon, AppleIcon, AppleTvIcon, CollectionQuestionQuestionIcon, IosIcon} from "../../icons";
import {useTheme} from "../../hooks";

const SupportedPlatforms = () => {
    const {colors} = useTheme();
    return (

        <Wrapper>
            <div className="container">
                <h2 className={'text-gradient'}>Supported platforms</h2>

                <div className="platforms">
                    <PlatformCard href={'https://apps.apple.com/app/rubyplay/id6468264752'} platformName={'Apple iPhone'}
                                  icon={<AppleIcon width={100} height={100} color={colors.title}/>}
                                  openContentText={'Download on the AppStore'}/>
                    {/*<PlatformCard platformName={'Android'} icon={<AndroidIcon width={200} height={100} color={colors.title}/>}*/}
                    {/*              openContentText={'Download from Google play'}/>*/}
                    <PlatformCard platformName={'Apple TV'}
                                  icon={<AppleTvIcon width={150} height={100} color={colors.title}/>} isComingSoon={true}/>
                    {/*<PlatformCard platformName={'Android TV'}*/}
                    {/*              icon={<AndroidTvIcon color={colors.title} width={200} height={100}/>} isComingSoon={true}/>*/}
                    <PlatformCard platformName={'And more'}
                                  icon={<CollectionQuestionQuestionIcon color={colors.title} width={200} height={100}/>}
                                  isComingSoon={true} noText={true}/>
                </div>
            </div>
        </Wrapper>
    );
};

const Wrapper = styled.div`
  width: 100%;

  margin-top: calc(10rem * calc(1 - 0));
  margin-bottom: calc(10rem);

  h2 {
    text-align: center;
    margin: 3rem 0;
  }

  .platforms {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    gap: 30px;
  }

  @media (max-width: 980px) {
    .platforms {
      justify-content: space-around;
    }
  }
`;

export default SupportedPlatforms;
