import React from 'react';
import styled from "styled-components";

const FunctionCard = ({step, title, text}) => {
    return (
        <Wrapper>
            <div className={'step text-gradient'}>{step}</div>
            <h3>{title}</h3>
            <p className={'fn__card__text'}>{text}</p>
        </Wrapper>
    );
};

const Wrapper = styled.div`
  max-width: 380px;
  padding: 1rem;
  border: 2px solid ${props => props.theme.divider};
  position: relative;
  padding-left: 5rem;
  border-radius: 5px;
  background-color: ${props => props.theme.headerBackground} !important;
  backdrop-filter: blur(12px);


  .step {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 0;
    font-size: 120px !important;
    color: ${props => props.theme.primary};
    line-height: 0;
  }

  h3 {
    text-align: left;
    color: ${props => props.theme.title};
  }

  .fn__card__text {
    margin-top: 0.5rem;
    text-align: left;
  }

  transition: 200ms;

  &:hover {
    transition: 200ms;
    border-color: ${props => props.theme.primary};
    //background-image: linear-gradient(45deg, var(--base) 50%, var(--complimentary2));
  }
`;

export default FunctionCard;
