import React, {useEffect} from 'react';
import styled from "styled-components";
import {useTheme} from "../../../hooks";
import {useNavigate, useSearchParams} from "react-router-dom";


const items = [
    {
        id: 1,
        title: 'Exploring Culinary Creativity: A Journey into the World of Cooking 🥘',
        description: `Cooking is an art form that
                    allows us to express ourselves, explore new flavors, and connect with different cultures.`,
        created_at: '2023-08-19 20:00'
    },
    {
        id: 2,
        title: 'Deliciously Simple: How to Cook a Perfect Roasted Chicken 🍗',
        description: `Roasted chicken is a classic comfort food that never goes out of style. It's a dish that can
                        impress.`,
        created_at: '2023-08-18 15:00'
    },
]
const RubyPlayBlogExample = () => {

    const {colors, setTheme} = useTheme();
    const navigation = useNavigate();
    const [query] = useSearchParams();
    const THEME = query.get('theme');

    useEffect(() => {
        setTheme(THEME === 'dark' ? 'dark' : 'light');
    }, [THEME]);

    const onNavigateToContent = (item) => {
        navigation(`/blog-example/${item.id}?title=${item.title}`);
    }

    return (
        <Wrapper>
            <div className="container">
                <h2>ChatGPT Blog 🤖</h2>
                <p>This blog is fully generated by ChatGPT. It generates a blog post every 5 hours.</p>
                <p>Tech stack: NodeJS, node-cron, chatgpt </p>
                <div className="blogs">
                    {items.map(item => <div onClick={() => onNavigateToContent(item)} className="card gradient-box">
                        <h3>{item.title}</h3>
                        <p>{item.description}</p>
                        <p className={'time'}>{item.created_at}</p>
                    </div>)}
                </div>
            </div>
        </Wrapper>
    );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  //overflow: hidden;

  h2 {
    color: ${props => props.theme.title};
    padding: 15px 0;
    text-align: center;
  }

  p {
    text-align: center;
  }


  .blogs {
    padding-top: 50px;
  }


  .card {
    cursor: pointer;
    border-radius: 5px;
    padding: 15px;
    max-width: 900px;

    background-color: ${props => props.theme.headerBackground};
    margin: 20px 0;
    margin-left: auto;
    margin-right: auto;

    h3 {
      color: ${props => props.theme.title};
      margin-bottom: 10px;
      font-size: 20px;
    }

    h3, p {
      text-align: left;
    }

    .time {
      margin-top: 15px;
    }
  }


  .gradient-box {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 90%;
    //max-width: 22em;
    position: relative;
    box-sizing: border-box;
    color: #FFF;
    background-clip: padding-box;
    /* !importanté */
    border: solid 5px transparent;
    /* !importanté */
    border-radius: 1em;
  }

  .gradient-box:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -3px;
    /* !importanté */
    border-radius: inherit;
    /* !importanté */
    background: linear-gradient(45deg,
    var(--base) 30%,
    var(--complimentary2));
    opacity: 0.6;
  }

`;

export default RubyPlayBlogExample;
