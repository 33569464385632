import * as React from "react"

const SvgComponent = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 866.3 132" {...props}>
        <path
            fill={props.color}
            d="M41.5 42.4c12.4 0 22.7 6.4 27.6 13.3V44.1H88v86.1H74.4a5.378 5.378 0 0 1-5.4-5.4v-6.2c-4.8 7-15.1 13.4-27.5 13.4C17.9 132 0 111.8 0 87.2s17.9-44.8 41.5-44.8m3.4 17.2c-15.3 0-25.7 12.1-25.7 27.6s10.3 27.6 25.7 27.6c15.3 0 25.7-12.1 25.7-27.6S60.3 59.6 44.9 59.6m64.6-15.5h19v11.7c5.2-8.6 15-13.4 26.2-13.4 20 0 32.9 14.1 32.9 36v51.8H174a5.378 5.378 0 0 1-5.4-5.4V81.5c0-13.6-6.9-21.9-17.9-21.9-12.6 0-22.2 9.8-22.2 28.2v42.4h-13.6a5.378 5.378 0 0 1-5.4-5.4Zm134.6-1.7c12.4 0 22.7 6.4 27.6 13.3V1.1h18.9v129.2H277a5.378 5.378 0 0 1-5.4-5.4v-6.2c-4.8 6.9-15.2 13.3-27.6 13.3-23.6 0-41.5-20.2-41.5-44.8.1-24.6 18-44.8 41.6-44.8m3.4 17.2c-15.3 0-25.7 12.1-25.7 27.6s10.3 27.6 25.7 27.6c15.3 0 25.7-12.1 25.7-27.6s-10.4-27.6-25.7-27.6m64.6-15.5H331v15.3a24.26 24.26 0 0 1 22.7-16.2 38.519 38.519 0 0 1 7.4.7v19.5a30.541 30.541 0 0 0-9.5-1.6c-10.9 0-20.7 9.1-20.7 26.4v42h-13.6a5.378 5.378 0 0 1-5.4-5.4V44.1ZM412.3 132c-25.5 0-45.1-19.8-45.1-44.8s19.6-44.8 45.1-44.8 45.1 19.8 45.1 44.8-19.6 44.8-45.1 44.8m0-17.6c15.2 0 25.8-11.9 25.8-27.2S427.4 60 412.3 60c-15.3 0-26 11.9-26 27.2s10.7 27.2 26 27.2m69.9-89.1a12.783 12.783 0 0 1-12.7-12.7A12.829 12.829 0 0 1 482.2 0a12.65 12.65 0 0 1 0 25.3m-9.4 18.8h18.9v86.1h-13.5a5.378 5.378 0 0 1-5.4-5.4V44.1Zm76-1.7c12.4 0 22.7 6.4 27.6 13.3V1.1h18.9v129.2h-13.6a5.378 5.378 0 0 1-5.4-5.4v-6.2c-4.8 6.9-15.2 13.3-27.6 13.3-23.6 0-41.5-20.2-41.5-44.8.1-24.6 18-44.8 41.6-44.8m3.5 17.2c-15.3 0-25.7 12.1-25.7 27.6s10.3 27.6 25.7 27.6c15.3 0 25.7-12.1 25.7-27.6s-10.4-27.6-25.7-27.6"/>
        <path
            d="M822.2 111.7a9.6 9.6 0 1 1 9.6-9.6 9.6 9.6 0 0 1-9.6 9.6m-105.7 0a9.6 9.6 0 1 1 9.6-9.6 9.6 9.6 0 0 1-9.6 9.6m109.1-57.6L844.8 21a3.963 3.963 0 1 0-6.9-3.9l-19.4 33.5A117.178 117.178 0 0 0 769.4 40a119.152 119.152 0 0 0-49.2 10.5L700.8 17a3.963 3.963 0 0 0-6.9 3.9L713 54a113.107 113.107 0 0 0-58.6 90.4h229.7a112.53 112.53 0 0 0-58.5-90.3"
            style={{
                fill: props.color,
            }}
            transform="translate(-17.8 -14)"
        />
    </svg>
)
export default SvgComponent
