import React from 'react';
import styled from "styled-components";

const Button = ({children, style, type, onClick,disabled = false}) => {

    if (type === 'outline') {
        return (<OutlineWrapper disabled={disabled} onClick={onClick} style={style} type={type}>
            {children}
        </OutlineWrapper>)
    }
    return (
        <Wrapper disabled={disabled} onClick={onClick} style={style} type={type}>
            {children}
        </Wrapper>
    );
};

const OutlineWrapper = styled.button`
  min-height: 40px;
  padding: 0.5rem 1rem;
  box-shadow: none;
  outline: none;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
  font-weight: 500;
  transition: 150ms;

  background-color: transparent;
  border: 2px solid #434343;

  color: ${props => props.theme.title};

  &:hover {
    transition: 150ms;
    background-color: #434343;
  }
`;

const Wrapper = styled.button`
  min-height: 40px;
  padding: 0.5rem 1rem;
  box-shadow: none;
  outline: none;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
  font-weight: 500;
  font-weight: 500;
  transition: 150ms;

  color: ${props => props.theme.buttonSecondaryText};
  background-color: ${props => props.theme.buttonSecondary};

  border: 2px solid transparent;

  &:hover {
    transition: 150ms;
    opacity: 0.8;
  }
`;

export default Button;
