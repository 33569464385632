import React from 'react';
import FunctionCard from "../cards/FunctionCard";
import styled from "styled-components";

const HowItWorks = () => {
    return (<Wrapper className={'features'}>
        <div className="container">
            <h2 className={'text-gradient'}>How it works</h2>

            <div className="feature__items">
                <FunctionCard step={1} title={'Adapt your API'}
                              text={'You do not have to waste your time and resources to create apps to deliver content to your users'}/>
                <FunctionCard step={2} title={'Submit your ruby'}
                              text={'When you are done integrating your API, submit your ruby for review'}/>
                <FunctionCard step={3} title={'Deliver to users'}
                              text={'After all the procedures you can deliver your app to your users'}/>
            </div>
        </div>
    </Wrapper>);
};

const Wrapper = styled.div`
  margin-top: calc(10rem * calc(1 - 0));
  margin-bottom: calc(10rem);
  
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  h2 {
    text-align: center;
    margin: 3rem 0;
  }

  .feature__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    gap: 20px;
  }

`;

export default HowItWorks;
