import React from 'react';
import styled from "styled-components";
import AppLogo from "../../assets/svg/logo.svg";
import {useLocation, useNavigate} from "react-router-dom";

const AppFooter = () => {

    const location = useLocation();
    const navigation = useNavigate();

    const navigate = (e, path) => {

        e.preventDefault();
        e.stopPropagation();

        if (window.location.host === 'pay.rubyplay.app') {
            window.location.href = `https://rubyplay.app${path}`;
            return;
        }

        navigation(path);
    }

    return (
        <Wrapper>
            <div className="container">
                <div className="top">
                    <div className="logo__wrapper link__item" onClick={e => navigate(e, '/')}>
                        <img className={'logo'} src={AppLogo}/>
                        <h1 className={'text-gradient'}>RubyPlay</h1>
                    </div>
                    <ul className="items">
                        <li>
                            <div onClick={e => navigate(e, '/integration')}
                                 className={`link__item ${location.pathname === '/integration' ? 'active' : ''}`}>
                                Integration
                            </div>
                        </li>
                        <li>
                            <div onClick={e => navigate(e, '/about-us')}
                                 className={`link__item ${location.pathname === '/about-us' ? 'active' : ''}`}>
                                About us
                            </div>
                        </li>
                        <li>
                            <div onClick={e => navigate(e, '/pp')}
                                 className={`link__item ${location.pathname === '/pp' ? 'active' : ''}`}>
                                Privacy Policy
                            </div>
                        </li>
                        <li>
                            <div onClick={e => navigate(e, '/tos')}
                                 className={`link__item ${location.pathname === '/tos' ? 'active' : ''}`}>
                                Terms and Conditions
                            </div>
                        </li>
                        <li>
                            <div onClick={e => navigate(e, '/contact')}
                                 className={`link__item ${location.pathname === '/contact' ? 'active' : ''}`}>
                                Contact
                            </div>
                        </li>
                    </ul>
                </div>
                {/*<div className="divider">*/}

                {/*</div>*/}
                {/*<div className="bottom">*/}
                {/*    <p>RETSEND LTD. 71-75, Shelton Street, Covent Garden, London, WC2H 9JQ, UNITED KINGDOM</p>*/}
                {/*</div>*/}
            </div>
        </Wrapper>
    );
};

const Wrapper = styled.div`
  width: 100%;
  margin-top: 10rem;
  background-color: ${props => props.theme.headerBackground} !important;
  backdrop-filter: blur(12px);

  .divider {
    border-bottom: 1px solid ${props => props.theme.divider};
  }

  .logo__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 2rem 0;
  }

  .items {
    //display: flex;
    //align-items: center;
  }

  a {
    //width: 150px;
  }

  .logo {
    width: 70px;
    height: 70px;
  }

  h1 {
    font-size: 2rem;
  }

  .bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    margin: 2rem 0;
  }

  .bottom p {
    text-align: center;
    font-size: 1rem;
  }

  .container {
    padding: 3rem 2rem 0 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  @media (max-width: 670px) {
    li {
      width: 100%;
      text-align: center;
      margin-top: 15px;
    }

    .top {
      justify-content: center;
    }
  }
`;

export default AppFooter;
