import React, {useRef} from 'react';
import styled from "styled-components";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {useEventListener} from "usehooks-ts";

const RubyPlayExampleBlogContent = () => {
    const {id} = useParams();
    const [query] = useSearchParams();
    const navigation = useNavigate();

    const barRef = useRef(null);


    useEventListener('onscroll', () => {
        if (!barRef.current) {
            return;
        }
        const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
        const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
        const scrolled = (winScroll / height) * 100;
        barRef.current.style.width = scrolled + "%";
    }, window);

    const onGoBack = () => {
        navigation(-1);
    }
    return (
        <Wrapper>
            <div className="container">
                <div className="header">
                    <div onClick={onGoBack} className="click">
                        <div className="progress-container">
                            <div ref={barRef} className="progress-bar" id="myBar"></div>
                        </div>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill="none"
                                  d="M16.7071 3.29289C16.3166 2.90237 15.6834 2.90237 15.2929 3.29289L7.29289 11.2929C6.90237 11.6834 6.90237 12.3166 7.29289 12.7071L15.2929 20.7071C15.6834 21.0976 16.3166 21.0976 16.7071 20.7071C17.0976 20.3166 17.0976 19.6834 16.7071 19.2929L9.41421 12L16.7071 4.70711C17.0976 4.31658 17.0976 3.68342 16.7071 3.29289Z"
                                  clipRule="evenodd" fillRule="evenodd" strokeWidth="1.5"></path>
                        </svg>
                    </div>
                </div>
                <h2>{query.get('title')}</h2>
                {id == 1 && <div className="content">
                    <h3>Introduction</h3>
                    <p>
                        Cooking is more than just a daily chore or a means of satisfying our hunger; it's an art form
                        that
                        allows us to express ourselves, explore new flavors, and connect with different cultures.
                        Whether
                        you're
                        a seasoned chef or a novice in the kitchen, cooking can be a delightful and rewarding
                        experience. In
                        this blog post, we'll dive into the wonderful world of cooking and explore how it can be a
                        source of
                        creativity and inspiration in our daily lives.
                    </p>

                    <h3>The Joy of Experimentation</h3>

                    <p>
                        One of the most exciting aspects of cooking is the opportunity to experiment with ingredients
                        and
                        flavors. You don't need to be a culinary genius to create delicious and unique dishes. Start by
                        picking
                        a cuisine that interests you, gather the essential ingredients, and let your imagination run
                        wild.
                        Mix
                        and match ingredients, adjust seasonings, and don't be afraid to take risks. Some of the world's
                        most
                        famous dishes were born from kitchen experiments.
                    </p>

                    <h3>The Sensory Experience</h3>

                    <p>
                        Cooking engages all of our senses, making it a truly immersive experience. The sizzle of
                        vegetables
                        in a
                        hot pan, the aroma of herbs and spices filling the air, the vibrant colors of fresh ingredients,
                        the
                        texture of a perfectly seared steak—each element contributes to the sensory delight of cooking.
                        Pay
                        attention to these sensory cues; they will guide you in creating a harmonious and appealing
                        dish.
                    </p>

                    <h3>The Art of Presentation</h3>

                    <p>
                        They say we eat with our eyes first, and presentation plays a crucial role in the culinary
                        world.
                        Whether you're plating a gourmet dinner for guests or simply preparing a meal for yourself, take
                        a
                        moment to arrange your dishes thoughtfully. Experiment with different plating techniques,
                        garnishes,
                        and
                        table settings. You'll be amazed at how a beautifully presented dish can elevate your dining
                        experience.
                    </p>

                    <h3>Cooking as a Cultural Journey</h3>

                    <p>
                        Exploring different cuisines allows us to travel the world without leaving our kitchen. Each
                        culture
                        has
                        its own unique culinary traditions, ingredients, and cooking methods. Embrace this diversity by
                        trying
                        out recipes from various parts of the globe. It's a fantastic way to learn about different
                        cultures
                        and
                        expand your palate.
                    </p>

                    <h3>
                        The Bonding Experience
                    </h3>

                    <p>
                        Cooking is not only a form of self-expression but also a way to connect with others. Gather your
                        friends
                        and family for a cooking session and enjoy the process together. It's a great way to bond, share
                        stories, and create lasting memories. Plus, there's something incredibly satisfying about
                        enjoying a
                        meal you've prepared with loved ones.
                    </p>

                    <h3>Conclusion</h3>

                    <p>
                        Cooking is an art that anyone can master, and it offers a world of creativity and discovery.
                        Whether
                        you're a passionate foodie or someone looking to improve their culinary skills, don't be afraid
                        to
                        get
                        in the kitchen and experiment. Through cooking, you can explore new flavors, cultures, and
                        experiences
                        while creating delicious meals that nourish both body and soul. So, roll up your sleeves, put on
                        your
                        apron, and embark on a culinary journey of your own. Happy cooking!
                    </p>
                </div>}
                {id == 2 && <div className="content">
                    <p>
                        Roasted chicken is a classic comfort food that never goes out of style. It's a dish that can
                        impress
                        dinner guests, but it's also perfect for a cozy night in. Plus, learning how to roast a chicken
                        is a
                        valuable skill that can be a centerpiece for various meals, from Sunday dinners to sandwiches
                        and
                        salads. In this blog post, we'll walk you through the steps to cook a succulent and flavorful
                        roasted chicken in your own kitchen.
                    </p>

                    <h3>Ingredients:</h3>

                    <ul>
                        <li>
                            1 whole chicken (about 4-5 pounds)
                        </li>
                        <li>
                            2 tablespoons olive oil
                        </li>
                        <li>
                            1 teaspoon salt
                        </li>
                        <li>
                            1/2 teaspoon freshly ground black pepper
                        </li>
                        <li>
                            1 teaspoon dried herbs (such as thyme, rosemary, or a poultry seasoning blend)
                        </li>
                        <li>
                            1 lemon, halved
                        </li>
                        <li>
                            4 cloves garlic, peeled
                        </li>
                        <li>
                            1 onion, quartered
                        </li>
                        <li>
                            Fresh herbs (rosemary or thyme sprigs, optional)
                        </li>
                        <li>
                            Cooking twine (optional)
                        </li>
                    </ul>


                    <h3>Instructions:</h3>

                    <p>
                        <b>Preheat Your Oven:</b> Preheat your oven to 425°F (220°C). Make sure your oven rack is in the
                        middle
                        position.
                    </p>

                    <p>
                        <b>Prepare the Chicken:</b> Start by removing the chicken from its packaging and pat it dry with
                        paper
                        towels. This step is crucial for achieving crispy skin. If there's any excess fat or loose skin,
                        trim it off.
                    </p>

                    <p>
                        <b>Season the Chicken:</b> Rub the chicken all over with olive oil, ensuring it's evenly coated.
                        Season
                        the
                        chicken inside and out with salt, pepper, and your choice of dried herbs. Don't forget to season
                        inside the cavity as well.
                    </p>

                    <p>
                        <b>Stuff the Chicken:</b> Place the halved lemon, garlic cloves, and onion quarters into the
                        chicken's
                        cavity. If you have fresh herbs like rosemary or thyme, you can add a few sprigs for extra
                        flavor.
                    </p>

                    <p>
                        <b>Truss the Chicken (Optional):</b> Trussing the chicken with cooking twine can help it cook
                        more
                        evenly
                        and maintain its shape. Cross the legs and tie them together with twine.
                    </p>

                    <p>
                        <b>Roast the Chicken:</b> Place the chicken on a roasting pan or a baking sheet with a wire
                        rack. This
                        allows for better air circulation and even cooking. Roast the chicken in the preheated oven for
                        about 1 hour and 15 minutes or until the internal temperature reaches 165°F (74°C) in the
                        thickest
                        part of the thigh.
                    </p>

                    <p>
                        <b>Rest the Chicken:</b> Once your chicken is beautifully golden brown and the internal
                        temperature is
                        right, remove it from the oven. Allow it to rest for 10-15 minutes before carving. This step
                        allows
                        the juices to redistribute, ensuring a moist and tender chicken.
                    </p>

                    <p>
                        <b>Carve and Serve:</b> Carve your roasted chicken into pieces and serve it with your favorite
                        sides,
                        whether it's roasted vegetables, mashed potatoes, or a fresh salad. Drizzle any pan juices over
                        the
                        chicken for added flavor.
                    </p>

                    <h3>Conclusion:</h3>

                    <p>
                        Cooking a roasted chicken may seem intimidating at first, but with these simple steps, you can
                        achieve a mouthwatering and impressive meal in no time. Whether you're cooking for a special
                        occasion or just craving some classic comfort food, a perfectly roasted chicken is always a
                        winner.
                        So, roll up your sleeves, preheat that oven, and get ready to enjoy the delicious rewards of
                        homemade roasted chicken. Bon appétit!
                    </p>
                </div>}
            </div>
        </Wrapper>
    );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  padding: 50px 10px;
  padding-top: 50px;

  .header {
    width: 100%;
    
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: ${props => props.theme.card};

    svg {
      margin-left: 10px;
      width: 30px;
      height: 30px;

      path {
        fill: ${props => props.theme.title};
      }
    }

    .progress-container {
      width: 100%;
      height: 3px;
      background: #ccc;
      margin-bottom: 5px;
    }

    /* The progress bar (scroll indicator) */

    .progress-bar {
      height: 3px;
      background: linear-gradient(45deg,
      var(--base) 30%,
      var(--complimentary2));
      width: 0%;
    }
  }

  h2 {
    text-align: center;
    color: ${props => props.theme.title};
    font-size: 30px;
  }


  h3 {
    color: ${props => props.theme.title};
    font-size: 1.6rem;
    margin: 2rem 0 1rem 0;
  }

  p {
    margin: 0.8rem 0;
  }


  .content {
    margin-top: 40px;
  }

  ul {
    li {
      display: block;
      margin: 0;
      color: ${props => props.theme.text};
      font-size: 1.2rem;
      margin-top: 0.5rem;
    }


    li::before {
      content: "\\2022"; /* Add content: \\2022 is the CSS Code/unicode for a bullet */
      color: ${props => props.theme.text}; /* Change the color */
      font-weight: bold; /* If you want it to be bold */
      display: inline-block; /* Needed to add space between the bullet and the text */
      width: 1em; /* Also needed for space (tweak if needed) */
      margin-left: -1em; /* Also needed for space (tweak if needed) */
    }
  }
`;

export default RubyPlayExampleBlogContent;
