import React from 'react';
import styled from "styled-components";

const FeatureCard = ({title, text}) => {
    return (
        <Wrapper>
            <h3 >{title}</h3>
            <p>{text}</p>
        </Wrapper>
    );
};

const Wrapper = styled.div`
  min-width: 300px;
  padding: 1rem;
  border: 2px solid transparent;
  position: relative;
  border-radius: 5px;
  background-color: ${props => props.theme.headerBackground} !important;

  h3 {
    margin-bottom: 0.5rem;
    color: ${props => props.theme.title};
  }

  transition: 200ms;

  &:hover {
    transition: 200ms;
    border-color: ${props => props.theme.primary};
    //background-image: linear-gradient(45deg, var(--base) 50%, var(--complimentary2));
  }
`;

export default FeatureCard;
