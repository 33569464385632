import React from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Home from "../pages/home";
import TermsOfUse from "../pages/termsOfUse";
import PrivacyPolicy from "../pages/privacyPolicy";
import AboutUs from "../pages/aboutUs";
import IntegrationPage from "../pages/integration";
import RubyPlayTwitchPlayer from "../pages/examples/twitch_player";
import RubyPlayBlogExample from "../pages/examples/blog";
import RubyPlayExampleBlogContent from "../pages/examples/blog/blog-content";
import ContactPage from "../pages/contact";
import TransactionPage from "../pages/transactions/TransactionPage";

const AppRoutes = () => {

    const domain = window.location.host;


    if (domain === 'pay.rubyplay.app') {
        return (<BrowserRouter>
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/:uid" element={<TransactionPage/>}/>
            </Routes>
        </BrowserRouter>);
    }

    return (
        <BrowserRouter basename={'/'}>
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/tos" element={<TermsOfUse/>}/>
                <Route path="/pp" element={<PrivacyPolicy/>}/>
                <Route path="/about-us" element={<AboutUs/>}/>
                <Route path="/integration" element={<IntegrationPage/>}/>
                <Route path="/contact" element={<ContactPage/>}/>

                <Route path="/twitch-player" element={<RubyPlayTwitchPlayer/>}/>

                <Route path="/blog-example" element={<RubyPlayBlogExample/>}/>
                <Route path="/blog-example/:id" element={<RubyPlayExampleBlogContent/>}/>
            </Routes>
        </BrowserRouter>
    );
};

export default AppRoutes;
