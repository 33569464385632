import React from 'react'

export default function ICCollectionQuestion(props) {
    const {color} = props;

    return (
        <>
            <svg width="24" height="24" viewBox="0 0 24 24" fill={color} xmlns="http://www.w3.org/2000/svg" {...props}>
                <path fill={color} d="M21 3H13V11H21V3Z" strokeWidth="1"></path>
                <path fill={color} d="M11 13H3V21H11V13Z" strokeWidth="1"></path>
                <path fill={color} d="M11 3H3V11H11V3Z" strokeWidth="1"></path>
                <path fill={color}
                      d="M16 21C16 20.4477 16.4477 20 17 20H17.01C17.5623 20 18.01 20.4477 18.01 21C18.01 21.5523 17.5623 22 17.01 22H17C16.4477 22 16 21.5523 16 21Z"
                      clipRule="evenodd" fillRule="evenodd" strokeWidth="1"></path>
                <path fill={color}
                      d="M16.6327 14.0917C16.498 14.1624 16.4169 14.2562 16.3526 14.3397C16.3447 14.35 16.3366 14.3602 16.3282 14.3702C16.0982 14.6462 16.0185 14.9749 15.9906 15.1766C15.9152 15.7237 15.4105 16.1061 14.8634 16.0306C14.3163 15.9552 13.9339 15.4505 14.0094 14.9034C14.0612 14.528 14.2194 13.7852 14.7789 13.1054C14.9189 12.9248 15.2143 12.5726 15.722 12.311L15.7328 12.3055L15.7328 12.3056C16.3442 11.9999 16.8958 12 17.0941 12L17.1 12C17.3635 12 18.0234 12.0289 18.7003 12.475L18.7047 12.4779L18.7047 12.478C19.5191 13.0209 19.7837 13.7958 19.859 14.0342C19.9414 14.2741 20.1296 15.0086 19.867 15.8501C19.702 16.4108 19.4024 16.7792 19.2319 16.9889C19.2233 16.9994 19.2151 17.0095 19.2072 17.0192L19.1944 17.0347C18.7485 17.5635 18.3109 17.8252 18.0419 17.9861C18.0304 17.993 18.0191 17.9997 18.0082 18.0062C18.0054 18.0079 18.0027 18.0096 17.9999 18.0112C17.9939 18.5583 17.5485 19 17 19C16.4477 19 16 18.5523 16 18C16 17.942 15.9988 17.8966 15.9976 17.8495C15.9972 17.8361 15.9969 17.8227 15.9965 17.8088C15.996 17.7845 15.9949 17.7382 15.9962 17.6922C15.9969 17.6694 15.9986 17.6255 16.0054 17.5731C16.0089 17.5462 16.0152 17.5056 16.0269 17.4574C16.0278 17.454 16.0287 17.4503 16.0297 17.4464C16.1883 16.7607 16.7101 16.451 16.9471 16.3104C16.9585 16.3037 16.9692 16.2973 16.9793 16.2913C16.9851 16.2877 16.991 16.2842 16.9968 16.2807C17.2343 16.1383 17.4327 16.0193 17.6596 15.7524C17.8287 15.5432 17.9025 15.4441 17.9494 15.2819C17.9517 15.274 17.9542 15.266 17.9567 15.2581C18.002 15.115 18.0077 14.9717 17.9967 14.8516C17.9874 14.751 17.9685 14.689 17.969 14.6889C17.9692 14.6888 17.9705 14.6928 17.9737 14.7011C17.9671 14.6837 17.9611 14.6661 17.9555 14.6483C17.923 14.5441 17.8402 14.3064 17.5975 14.1435C17.3951 14.011 17.1962 14 17.1 14C16.9743 14 16.8129 14.0032 16.6327 14.0917Z"
                      clipRule="evenodd" fillRule="evenodd" strokeWidth="1"></path>
            </svg>
        </>
    )
}
