import React, {useEffect, useRef, useState} from 'react';
import Layout from "../../layouts/Layout";
import Button from "../../components/buttons/Button";
import FAQ from "../../components/main/FAQ";
import AppFooter from "../../components/common/AppFooter";
import styled from "styled-components";
import {useParams, useSearchParams} from "react-router-dom";
import {DangerIcon} from "../../icons";
import MoonLoader from "react-spinners/MoonLoader";
import {useInterval} from "usehooks-ts";

const TransactionPage = () => {

    const [query] = useSearchParams();
    const {uid} = useParams();

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [transaction, setTransaction] = useState(null);
    const [ruby, setRuby] = useState(null);

    const didRun = useRef(false);


    useEffect(() => {
        fetchTransactionInfo();
    }, []);

    useInterval(() => {
        fetchTransactionInfo(true);
    }, 10000);

    const fetchTransactionInfo = async (isRefreshing = false) => {

        if (didRun.current && !isRefreshing) {
            return;
        }

        didRun.current = true;
        if (!isRefreshing) {
            setIsLoading(true);
        }

        const request = await fetch(`https://transaction.rubyplay.app/t/${uid}`)
            .then(res => res.json())
            .then(res => res).catch(err => ({
                error: err
            }));

        if (!isRefreshing) {
            setIsLoading(false);
        }


        if (request['error']) {
            setError(request['error']);
        }


        if (request['transaction']) {
            setTransaction(request['transaction']);
            setRuby(request['ruby']);
        }

        console.log('request', request);
    }

    const onSubmitTransaction = () => {
        if (!transaction) {
            return;
        }
        window.location.href = transaction['payment_url'];
    }

    return (
        <Layout>
            <Wrapper>
                <h2>Transaction <br/>{uid}</h2>

                <div className="transaction">
                    {isLoading && <div className="loading-container">
                        <div className="circle"></div>
                        <div className="circle"></div>
                        <div className="circle"></div>
                        <div className="circle"></div>
                        <div className="circle"></div>
                        <div className="circle"></div>
                        <div className="circle"></div>
                        <div className="circle"></div>
                        <div className="circle"></div>
                        <div className="circle"></div>
                        <div className="circle"></div>
                        <div className="circle"></div>
                        <div className="circle"></div>
                        <div className="circle"></div>
                        <div className="circle"></div>
                        <div className="circle"></div>
                    </div>}
                    {error && <div className={'error__container'}>
                        <DangerIcon width={150} height={150}/>
                        <p>{error}</p>
                    </div>}
                    {transaction && <div className="transaction_content">

                        <div className="info__table">
                            <div className="info__item">
                                <div>
                                    <p>Subscribing for Ruby named</p>
                                </div>
                                <div>
                                    <p>
                                        <b>{ruby?.name}</b>
                                    </p>
                                </div>
                            </div>
                            {/*<div className="info__item">*/}
                            {/*    <div>*/}
                            {/*        <p>Plan code</p>*/}
                            {/*    </div>*/}
                            {/*    <div>*/}
                            {/*        <p>*/}
                            {/*            <b>{transaction['plan']['code']}</b>*/}
                            {/*        </p>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            <div className="info__item">
                                <div>
                                    <p>What am i subscribing for</p>
                                </div>
                                <div>
                                    <p>
                                        <b>{transaction['plan']['name']}</b>
                                    </p>
                                </div>
                            </div>
                            <div className="divider"></div>
                            <div className="info__item">
                                <div>
                                    <p>Payment method</p>
                                </div>
                                <div>
                                    <p>
                                        <b>Crypto</b>
                                    </p>
                                </div>
                            </div>
                            <div className="info__item">
                                <div>
                                    <p>Payment amount</p>
                                </div>
                                <div>
                                    <p>
                                        <b>{transaction['amount']}</b>
                                    </p>
                                </div>
                            </div>
                            <div className="info__item">
                                <div>
                                    <p>Payment currency</p>
                                </div>
                                <div>
                                    <p>
                                        <b>{transaction['currency']}</b>
                                    </p>
                                </div>
                            </div>
                            <div className="divider"></div>
                            <div className="info__item">
                                <div>
                                    <p>User ID</p>
                                </div>
                                <div>
                                    <p>
                                        <b>{transaction['user_id']}</b>
                                    </p>
                                </div>
                            </div>
                            <div className="info__item">
                                <div>
                                    <p>Transaction ID</p>
                                </div>
                                <div>
                                    <p>
                                        <b>{transaction['uid']}</b>
                                    </p>
                                </div>
                            </div>
                            <div className="info__item">
                                <div>
                                    <p>Transaction status</p>
                                </div>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <p style={{marginRight: 10}}>
                                        <b>{transaction['status'] === 'created' ? 'Awaiting confirmation' : transaction['status']}</b>
                                    </p>

                                    {transaction['status'] === 'canceled' && '❌'}
                                    {transaction['status'] === 'failed' && '❌'}
                                    {transaction['status'] === 'wrong_amount' && '❌'}
                                    {transaction['status'] === 'paid' && '✅'}

                                    {transaction['status'] === 'created' && <MoonLoader
                                        color={'#fff'}
                                        loading={true}
                                        size={20}
                                        aria-label="Loading Spinner"
                                        data-testid="loader"
                                    />}
                                </div>
                            </div>
                            {transaction['status'] === 'created' && <div className="info__item">
                                <div>
                                    <p>Expires at (GMT+2)</p>
                                </div>
                                <div>
                                    <p>
                                        <b>{transaction['expires_at']}</b>
                                    </p>
                                </div>
                            </div>}

                            {(transaction['status'] === 'wrong_amount' || transaction['status'] === 'failed') &&
                                <div className="divider"></div>}
                            {transaction['status'] === 'wrong_amount' && <p style={{marginTop: 10, textAlign: 'left'}}>
                                Wrong amount means that you did not pay enough for the subscription. <br/> The
                                transaction is
                                automatically canceled <br/>
                            </p>}

                            {transaction['status'] === 'failed' && <p style={{marginTop: 10, textAlign: 'left'}}>
                                The transaction has failed for some reason. <br/> Please contact your Ruby
                                administrators for
                                further details <br/>
                            </p>}
                        </div>
                        {(transaction?.status === 'created' || transaction?.status === 'wrong_amount_waiting') && <Button
                            onClick={onSubmitTransaction}>
                            Pay {transaction['amount']} {transaction['currency']}
                        </Button>}
                    </div>}
                </div>


                <AppFooter/>
            </Wrapper>
        </Layout>
    );
};

const Wrapper = styled.div`
  width: 100%;
  padding-top: 5rem;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  h2 {
    text-align: center;
    color: ${props => props.theme.title};
    margin: 3rem 0;
  }


  p {
    text-align: center;
  }


  .info__item {
    width: 600px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px 0;
  }

  .transaction {
    width: 100%;
    //min-height: 500px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .divider {
    width: 100%;
    margin: 10px auto;
    border-bottom: 1px solid ${props => props.theme.divider};
  }

  .error__container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    p {
      margin-top: 20px;
    }
  }


  .transaction_content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 50px;
  }

  .info__table {
    width: 100%;
    background-color: ${props => props.theme.headerBackground} !important;
    backdrop-filter: blur(12px);
    padding: 1rem;
    position: relative;
    border-radius: 5px;
    border: 2px solid transparent;

    opacity: ${props => props.isComingSoon ? 0.4 : 1};

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }


  .loading-container {
    position: relative;
    display: block;
    width: 190px;
    height: 190px;
    transform-style: preserve-3d;
    transform: perspective(1000px) rotateY(45deg) rotateX(45deg);
  }

  .circle:nth-child(0) {
    position: absolute;
    background: transparent;
    border: 2px solid ${props => props.theme.title};
    border-radius: 50%;
    left: 0px;
    top: 0px;
    width: 190px;
    height: 190px;
    -webkit-animation: spin Infinitys infinite linear;
    animation: spin Infinitys infinite linear;
  }

  .circle:nth-child(1) {
    position: absolute;
    background: transparent;
    border: 2px solid ${props => props.theme.title};
    border-radius: 50%;
    left: 5.9375px;
    top: 5.9375px;
    width: 178.125px;
    height: 178.125px;
    -webkit-animation: spin 18s infinite linear;
    animation: spin 18s infinite linear;
  }

  .circle:nth-child(2) {
    position: absolute;
    background: transparent;
    border: 2px solid ${props => props.theme.title};
    border-radius: 50%;
    left: 11.875px;
    top: 11.875px;
    width: 166.25px;
    height: 166.25px;
    -webkit-animation: spin 9s infinite linear;
    animation: spin 9s infinite linear;
  }

  .circle:nth-child(3) {
    position: absolute;
    background: transparent;
    border: 2px solid ${props => props.theme.title};
    border-radius: 50%;
    left: 17.8125px;
    top: 17.8125px;
    width: 154.375px;
    height: 154.375px;
    -webkit-animation: spin 6s infinite linear;
    animation: spin 6s infinite linear;
  }

  .circle:nth-child(4) {
    position: absolute;
    background: transparent;
    border: 2px solid ${props => props.theme.title};
    border-radius: 50%;
    left: 23.75px;
    top: 23.75px;
    width: 142.5px;
    height: 142.5px;
    -webkit-animation: spin 4.5s infinite linear;
    animation: spin 4.5s infinite linear;
  }

  .circle:nth-child(5) {
    position: absolute;
    background: transparent;
    border: 2px solid ${props => props.theme.title};
    border-radius: 50%;
    left: 29.6875px;
    top: 29.6875px;
    width: 130.625px;
    height: 130.625px;
    -webkit-animation: spin 3.6s infinite linear;
    animation: spin 3.6s infinite linear;
  }

  .circle:nth-child(6) {
    position: absolute;
    background: transparent;
    border: 2px solid ${props => props.theme.title};
    border-radius: 50%;
    left: 35.625px;
    top: 35.625px;
    width: 118.75px;
    height: 118.75px;
    -webkit-animation: spin 3s infinite linear;
    animation: spin 3s infinite linear;
  }

  .circle:nth-child(7) {
    position: absolute;
    background: transparent;
    border: 2px solid ${props => props.theme.title};
    border-radius: 50%;
    left: 41.5625px;
    top: 41.5625px;
    width: 106.875px;
    height: 106.875px;
    -webkit-animation: spin 2.571428571428572s infinite linear;
    animation: spin 2.571428571428572s infinite linear;
  }

  .circle:nth-child(8) {
    position: absolute;
    background: transparent;
    border: 2px solid ${props => props.theme.title};
    border-radius: 50%;
    left: 47.5px;
    top: 47.5px;
    width: 95px;
    height: 95px;
    -webkit-animation: spin 2.25s infinite linear;
    animation: spin 2.25s infinite linear;
  }

  .circle:nth-child(9) {
    position: absolute;
    background: transparent;
    border: 2px solid ${props => props.theme.title};
    border-radius: 50%;
    left: 53.4375px;
    top: 53.4375px;
    width: 83.125px;
    height: 83.125px;
    -webkit-animation: spin 2s infinite linear;
    animation: spin 2s infinite linear;
  }

  .circle:nth-child(10) {
    position: absolute;
    background: transparent;
    border: 2px solid ${props => props.theme.title};
    border-radius: 50%;
    left: 59.375px;
    top: 59.375px;
    width: 71.25px;
    height: 71.25px;
    -webkit-animation: spin 1.8s infinite linear;
    animation: spin 1.8s infinite linear;
  }

  .circle:nth-child(11) {
    position: absolute;
    background: transparent;
    border: 2px solid ${props => props.theme.title};
    border-radius: 50%;
    left: 65.3125px;
    top: 65.3125px;
    width: 59.375px;
    height: 59.375px;
    -webkit-animation: spin 1.636363636363636s infinite linear;
    animation: spin 1.636363636363636s infinite linear;
  }

  .circle:nth-child(12) {
    position: absolute;
    background: transparent;
    border: 2px solid ${props => props.theme.title};
    border-radius: 50%;
    left: 71.25px;
    top: 71.25px;
    width: 47.5px;
    height: 47.5px;
    -webkit-animation: spin 1.5s infinite linear;
    animation: spin 1.5s infinite linear;
  }

  .circle:nth-child(13) {
    position: absolute;
    background: transparent;
    border: 2px solid ${props => props.theme.title};
    border-radius: 50%;
    left: 77.1875px;
    top: 77.1875px;
    width: 35.625px;
    height: 35.625px;
    -webkit-animation: spin 1.384615384615385s infinite linear;
    animation: spin 1.384615384615385s infinite linear;
  }

  .circle:nth-child(14) {
    position: absolute;
    background: transparent;
    border: 2px solid ${props => props.theme.title};
    border-radius: 50%;
    left: 83.125px;
    top: 83.125px;
    width: 23.75px;
    height: 23.75px;
    -webkit-animation: spin 1.285714285714286s infinite linear;
    animation: spin 1.285714285714286s infinite linear;
  }

  .circle:nth-child(15) {
    position: absolute;
    background: transparent;
    border: 2px solid ${props => props.theme.title};
    border-radius: 50%;
    left: 89.0625px;
    top: 89.0625px;
    width: 11.875px;
    height: 11.875px;
    -webkit-animation: spin 1.2s infinite linear;
    animation: spin 1.2s infinite linear;
  }

  .circle:nth-child(16) {
    position: absolute;
    background: transparent;
    border: 2px solid ${props => props.theme.title};
    border-radius: 50%;
    left: 95px;
    top: 95px;
    width: 0px;
    height: 0px;
    -webkit-animation: spin 1.125s infinite linear;
    animation: spin 1.125s infinite linear;
  }

  .circle:nth-child(2n) {
    border: 2px dashed rgba(255, 255, 255, 0.5);
  }

  .circle:last-child {
    display: none;
  }

  @-webkit-keyframes spin {
    0% {
      transform: rotateX(0deg);
    }
    100% {
      transform: rotateX(360deg);
    }
  }
  @keyframes spin {
    0% {
      transform: rotateX(0deg);
    }
    100% {
      transform: rotateX(360deg);
    }
  }

  @media (max-width: 789px) {
    h2 {
      font-size: 30px;
    }
  }
  @media (max-width: 643px) {
    .transaction {
      padding: 0 20px;
      width: 100%;
    }

    .transaction_content {
      width: 100%;
    }

    .info__table {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .info__item {
      margin: 10px 0;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  @media (max-width: 501px) {
    h2 {
      font-size: 20px;
    }
  }

`;

export default TransactionPage;
